.root {
	list-style: none;
	composes: g-grid-container from global;
	display: grid;
	row-gap: var(--wpl-spacing-13);
	margin-top: var(--wpl-spacing-12);
	margin-bottom: var(--wpl-spacing-12);

	@media (--medium-up) {
		margin-top: var(--wpl-spacing-15);
		margin-bottom: var(--wpl-spacing-15);
		row-gap: var(--wpl-spacing-15);
	}
}

.useCaseItem {
	display: flex;
	align-items: center;
	flex-direction: column;

	--text-grid-column: 1 / 6;
	--image-grid-column: 7 / -1;

	&:nth-child(odd) {
		--text-grid-column: 8 / -1;
		--image-grid-column: 1 / span 6;
	}
}

.content {
	align-items: center;
	composes: g-grid from global;
	row-gap: var(--wpl-spacing-09);

	@media (--medium-up) {
		grid-template-rows: 1fr;
	}
}

.heading {
	text-align: center;
	max-width: 740px;
}

.textContent {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: var(--text-grid-column);
		grid-row: 1;
	}
}

.list {
	padding-left: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: var(--wpl-spacing-03);

	@media (--medium-up) {
		gap: var(--wpl-spacing-05);
	}
}

.listItem {
	display: flex;

	& p {
		margin-bottom: 0;
		margin-left: calc(1em - 6px);
	}

	& .bullet {
		width: 6px;
		height: 6px;
		background: var(--wpl-neutral-500);
		flex-shrink: 0;

		/* text line-height divided by 2 minus bullet height divided by 2 */
		margin-top: calc(0.8335em - 3px);
	}
}

.ctaWrapper {
	margin-top: var(--wpl-spacing-03);

	@media (--medium-up) {
		margin-top: var(--wpl-spacing-05);
	}
}

.imageWrapper {
	display: flex;
	grid-column: 1 / -1;
	align-items: center;
	justify-content: center;

	@media (--medium-up) {
		grid-column: var(--image-grid-column);
	}
}

.image {
	max-width: 100%;
	height: auto;
}
