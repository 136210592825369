.keyWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 100%;
	column-gap: var(--hdsplus-spacing-09);
	row-gap: var(--hdsplus-spacing-07);
}

.key {
	align-items: center;
	display: flex;
}

.dot {
	--size: 16px;

	flex-shrink: 0;
	border-radius: var(--size);
	height: var(--size);
	width: var(--size);
	margin-right: var(--hdsplus-spacing-03);
}

.name {
	composes: hdsplus-typography-body-200 from global;
	margin: 0;
	color: var(--token-color-foreground-primary);
}
