.controls {
	display: flex;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.controlItem {
	align-items: center;
	display: flex;
	justify-content: center;
	list-style: none;
}

.controlButton {
	align-items: center;
	background: transparent;
	border: none;
	box-shadow: none;
	cursor: pointer;
	display: flex;
	padding: 4px;
	justify-content: center;
}

.dot {
	background: var(--control-button-background, var(--wpl-neutral-100));
	border-radius: 50%;
	height: 10px;
	transition: background 0.2s ease-in-out;
	width: 10px;
}

.isActive {
	--control-button-background: var(--wpl-neutral-800);
}
