.quotesWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.quotes {
	display: grid;
	justify-content: center;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.quoteSlide {
	display: flex;
	flex-direction: column;
	grid-area: 1 / 1;
	justify-content: center;
	padding-bottom: var(--wpl-spacing-03);

	@media (--medium-up) {
		padding-bottom: var(--wpl-spacing-06);
	}
}

.quoteFigure {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
}

.blockquote {
	margin: 0;
	padding: 0;
	text-align: center;
}

.quoteText {
	font-family: var(--font-display);
	font-size: 1.25rem;
	line-height: 1.6em;
	letter-spacing: -0.006em;

	@media (--medium-up) {
		font-size: 1.5rem;
		line-height: 1.667em;
	}
}

.quoteCaption {
	text-align: center;
	width: 100%;
}
