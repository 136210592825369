.root {
	border-bottom: 1px solid var(--wpl-neutral-100);
}

.inner {
	composes: g-grid from global;
	composes: g-grid-container from global;
	row-gap: var(--wpl-spacing-07);
	margin-top: var(--wpl-spacing-14);
	margin-bottom: var(--wpl-spacing-14);
}

.introWrapper {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / span 5;
	}
}

.statsWrapper {
	grid-column: 1 / -1;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: var(--column-gap);
	padding-top: var(--wpl-spacing-07);
	border-top: 1px solid var(--wpl-neutral-100);

	@media (--small) {
		--columns: 1;
		--column-gap: var(--wpl-spacing-02);
	}

	@media (--medium-up) {
		--columns: 2;
		--column-gap: var(--wpl-spacing-05);

		grid-column: 7 / -1;
		padding-top: 0;
		border-top: 0;
	}

	@media (--large) {
		--column-gap: var(--wpl-spacing-06);
	}
}

.statItem {
	@media (--medium-up) {
		padding-top: var(--wpl-spacing-07);
		border-top: 1px solid var(--wpl-neutral-100);
	}
}

.ctaWrapper {
	padding-top: var(--wpl-spacing-03);

	@media (--medium-up) {
		padding-top: var(--wpl-spacing-05);
	}
}
