.heading {
	@media (--large) {
		font-size: 80px;
		line-height: 60px;
		margin-bottom: 18px;
	}
}

.number {
	font-feature-settings: 'tnum', 'tnum';
	font-variant-numeric: tabular-nums;
}

.subheading {
	@media (--medium-up) {
		width: 90%;
	}
}
