.logos {
	padding: 0;
	margin: 0;
	list-style: none;
	background: var(--wpl-neutral-75);
	display: grid;
	grid-auto-rows: minmax(0, 1fr);
	grid-gap: 1px;

	--columns: 2;

	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));

	@media (--large) {
		--columns: 3;
	}
}

.logoItem {
	align-items: center;
	background: var(--wpl-neutral-0);
	display: flex;
	justify-content: center;
	padding: var(--wpl-spacing-05) 0;
}

.logo {
	max-width: 90%;
	height: auto;
}
