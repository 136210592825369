.chart-container {
	position: relative;
	background: var(--materia-accent-border-color, var(--token-color-border-primary));
	border-radius: var(--hdsplus-spacing-04);
	margin: 0;
	padding: 2rem;
	isolation: isolate;
	&.multiple {
		margin-top: 2rem;
	}
	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		inset: var(--materia-accent-border-width, 1px);
		background-color: var(--token-color-surface-primary);
		border-radius: calc(var(--hdsplus-spacing-04) - var(--materia-accent-border-width, 1px));
	}
}

.chart {
	position: relative;
	margin-inline: auto;
	&.regular {
		aspect-ratio: 7 / 4;
		max-height: 540px;
	}
	&.small {
		height: 360px;
	}
	&.xsmall {
		height: 280px;
	}
	& > div {
		position: absolute;
	}
	& svg {
		display: block;
	}
	& figcaption {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border-width: 0;
	}
}

.legend {
	display: grid;
	gap: 1rem 2rem;
	list-style: none;
	margin: 0;
	padding: 0;
	color: var(--token-color-foreground-primary);
	& li {
		display: flex;
		gap: 8px;
		line-height: 1.25em;
	}
}

.legend-dot {
	flex-shrink: 0;
	width: 20px;
	height: 20px;
}

/* Style overrides for the tab indicator if an accent border is defined */
.tab {
	& ~ :global(.hds-tabs__tab-indicator) {
		background: var(--materia-accent-border-color, var(--token-color-foreground-action));
	}
	&:global(.hds-tabs__tab.hds-tabs__tab--is-selected):hover ~ :global(.hds-tabs__tab-indicator) {
		background: var(--materia-accent-border-color, var(--token-color-foreground-action-hover));
	}
}