.chartContainer {
	position: relative;
	background: var(
		--materia-accent-border-color,
		var(--token-color-border-primary)
	);
	border-radius: var(--hdsplus-spacing-04);
	margin: 0;
	padding: var(--hdsplus-spacing-08);
	isolation: isolate;

	&.multiple {
		margin-top: var(--hdsplus-spacing-08);
	}

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		inset: var(--materia-accent-border-width, 1px);
		background-color: var(--token-color-surface-primary);
		border-radius: calc(
			var(--hdsplus-spacing-04) - var(--materia-accent-border-width, 1px)
		);
	}

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-11) var(--hdsplus-spacing-15);
	}
}

.caption {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

/* Style overrides for the tab indicator if an accent border is defined */
.tab {
	& ~ :global(.hds-tabs__tab-indicator) {
		background: var(--materia-accent-border-color, var(--token-color-foreground-action));
	}
	&:global(.hds-tabs__tab.hds-tabs__tab--is-selected):hover ~ :global(.hds-tabs__tab-indicator) {
		background: var(--materia-accent-border-color, var(--token-color-foreground-action-hover));
	}
}