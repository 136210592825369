.root {
	composes: g-grid-container from global;
	composes: g-grid from global;
	row-gap: var(--wpl-spacing-09);
	padding-bottom: var(--wpl-spacing-12);
	align-items: center;

	@media (--medium-up) {
		padding-bottom: var(--wpl-spacing-15);
	}
}

.quotesContainer {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / span 5;
	}
}

.logosContainer {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
	}
}
